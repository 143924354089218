import type { GatsbyBrowser } from 'gatsby';

const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  try {
    const preloadSparkScript = document.getElementById('preload-spark');

    if (!preloadSparkScript) {
      const preloadSpark = document.createElement('iframe');
      preloadSpark.id = 'preload-spark';
      preloadSpark.style.display = 'none';
      preloadSpark.src = 'https://lucid.app/documents/preloadChart';
      preloadSpark.dataset.osano = 'ESSENTIAL';

      const loadPreloadScripts = () => {
        document.getElementsByTagName('body')[0].appendChild(preloadSpark);
        window.removeEventListener('scroll', loadPreloadScripts);
      };

      window.addEventListener('scroll', loadPreloadScripts);
    }
  } catch (error) {
    console.error(`onClientEntry: ${error}`);
  }
};

export { onClientEntry };
